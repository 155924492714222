<!--
 * @Description:人行道停车运营管理 停车场管理 停车场管理 parkingManage
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-03-16 19:43:30
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainbody">
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="停车场名称">
              <el-select class="search-input"
                         clearable
                         v-model="searchForm.parkId"
                         placeholder="全部">
                <el-option v-for="(item, index) in parkNameList"
                           :key="index"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="地址">
              <el-input v-model="searchForm.address"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="联系人">
              <el-input v-model="searchForm.contact"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="联系方式">
              <el-input v-model="searchForm.contactPhoneNumber"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topSecondLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="计费规则">
              <el-select class="search-input"
                         clearable
                         v-model="searchForm.billingRuleDefId"
                         placeholder="全部">
                <el-option v-for="(item, index) in billingRulesList"
                           :key="index"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="停车场类型">
              <el-select v-model="searchForm.isEnabled"
                         filterable
                         placeholder="全部">
                <el-option v-for="item in operateList"
                           :key="item.isEnabled"
                           :label="item.name"
                           :value="item.isEnabled">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="handleClickSearch">查 询</el-button>
          <el-button type="info"
                     style="margin-left:17px"
                     @click="resetForm">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>停车场</span>
        <el-row class="topButton">
          <el-button type="success"
                     style="margin-left:17px"
                     v-show="insert_user"
                     @click="handleNew">新 增</el-button>
          <el-button type="success"
                     style="margin-left:17px"
                     v-show="import_dialog"
                     @click="importDialog=true">导 入</el-button>
          <el-button type="danger"
                     style="margin-left:17px"
                     @click="deleteList">批量删除</el-button>
        </el-row>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList"
                  :tableFun="tableFun">
          <template slot="isFirst">
            <el-table-column type="selection"></el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="是否运营">
              <template slot-scope="scope">
                <el-switch v-model="scope.row.isEnabled"
                           inactive-color="#ff4949"
                           active-color="#13ce66"
                           :inactive-value="'0'"
                           :active-value="'1'"
                           :disabled = "isDisabled"
                           @change="changeSwitch(scope.row)">
                </el-switch>
              </template>
            </el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="操作"
                             class-name='class-name'>
              <template slot-scope="scope">
                <el-row>
                  <el-col :span="8">
                    <el-button @click="handleOrderUpdate(scope.$index, scope.row)"
                               type="text"
                               v-show="click_update"
                               size="medium">修改</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button type="text"
                               size="medium"
                               v-show="click_delete"
                               @click="handleOrderDelete(scope.$index, scope.row)">删除</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button type="text"
                               size="medium"
                               v-show="update_yuwei"
                               @click="handleClickUpdateYuwei(scope.row)">余位修改</el-button>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>

    <!-- 新增 -->
    <el-dialog title="新增停车场"
               append-to-body
               @close="popClose"
               :visible.sync="dialogVisible">
      <el-form :model="instreForm"
               :rules="rules"
               ref="instreForm"
               size="small"
               label-width="110px">
        <!-- 第1行 -->
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="停车场编号"
                          prop="parkId">
              <el-input v-model="instreForm.parkId"
                        style="width:63%"
                        class="width input"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="停车场名称"
                          prop="parkName">
              <el-input v-model="instreForm.parkName"
                        style="width:63%"
                        class="width input"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 第2行 -->
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="归属企业"
                          prop="companyId">
              <el-select class="width"
                         style="width:63%"
                         v-model="instreForm.companyId"
                         placeholder="请选择">
                <el-option v-for="(item, index) in enterpriseList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="计费规则"
                          prop="billingRuleDefId">
              <el-select class="width"
                         style="width:63%"
                         v-model="instreForm.billingRuleDefId"
                         placeholder="请选择">
                <el-option v-for="(item, index) in billingRulesList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 第3行 -->
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="行政区域"
                          prop="areaCode">
              <el-select v-model="instreForm.areaCode"
                         style="width:63%"
                         placeholder="请选择"
                         @change="handleChangeAreaCode">
                <el-option v-for="(item, index) in regionCodeList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属路段"
                          prop="roadCode">
              <el-select v-model="instreForm.roadCode"
                         style="width:63%"
                         placeholder="请选择">
                <el-option v-for="(item, index) in roadList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 第6行 -->
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="规划车位数"
                          prop="parkSpaceNum">
              <el-input class="width"
                        placeholder="请输入"
                        style="width:63%"
                        v-model="instreForm.parkSpaceNum"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="地址"
                          prop="address">
              <el-input class="width"
                        placeholder="请输入"
                        style="width:63%"
                        v-model="instreForm.address"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12"></el-col>
          <el-col :span="12">
            <el-form-item>
              <div class='mapContent'>
                <el-button type="success"
                           size="medium"
                           @click="mapClick">地图</el-button>
                <el-row class="map-container"
                        v-if="dialogVisibleMap">
                  <div id="map"></div>
                </el-row>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 第5行 -->
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="经度"
                          prop="longitude">
              <el-input class="width"
                        placeholder="请输入"
                        style="width:63%"
                        v-model="instreForm.longitude"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="纬度"
                          prop="latitude">
              <el-input class="width"
                        placeholder="请输入"
                        style="width:63%"
                        v-model="instreForm.latitude"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 第7行 -->
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="联系人"
                          prop="contact">
              <el-input class="width"
                        placeholder="请输入"
                        style="width:63%"
                        v-model="instreForm.contact"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人电话"
                          prop="contactPhoneNumber">
              <el-input class="width"
                        placeholder="请输入"
                        style="width:63%"
                        v-model="instreForm.contactPhoneNumber"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-row class="dt-button-box">
            <el-button type="success"
                       size="medium"
                       @click="submitForm('instreForm')">确 定</el-button>
            <el-button class="Fbutton"
                       type="info"
                       @click="dialogVisible = false">取 消</el-button>
          </el-row>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 修改 -->
    <el-dialog title="修改停车场"
               append-to-body
               :visible.sync="updateVisible">
      <el-form :model="updateForm"
               :rules="rules"
               ref="updateForm"
               size="small"
               label-width="110px">
        <!-- 第1行 -->
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="停车场编号"
                          prop="parkId">
              <el-input v-model="updateForm.parkId"
                        style="width:63%"
                        class="width input"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="停车场名称"
                          prop="parkName">
              <el-input v-model="updateForm.parkName"
                        style="width:63%"
                        class="width input"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 第2行 -->
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="归属企业"
                          prop="companyId">
              <el-select class="width"
                         style="width:63%"
                         v-model="updateForm.companyId"
                         placeholder="请选择">
                <el-option v-for="(item, index) in enterpriseList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="计费规则"
                          prop="billingRuleDefId">
              <el-select class="width"
                         style="width:63%"
                         v-model="updateForm.billingRuleDefId"
                         placeholder="请选择">
                <el-option v-for="(item, index) in billingRulesList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 第3行 -->
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="行政区域"
                          prop="areaCode">
              <el-select v-model="updateForm.areaCode"
                         style="width:63%"
                         placeholder="请选择"
                         @change="handleChangeAreaCode">
                <el-option v-for="(item, index) in regionCodeList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属路段"
                          prop="roadCode">
              <el-select v-model="updateForm.roadCode"
                         style="width:63%"
                         placeholder="请选择">
                <el-option v-for="(item, index) in roadList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 第6行 -->
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="规划车位数"
                          prop="parkSpaceNum">
              <el-input class="width"
                        placeholder="请输入"
                        style="width:63%"
                        v-model="updateForm.parkSpaceNum"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="地址"
                          prop="address">
              <el-input class="width"
                        placeholder="请输入"
                        style='width: 63%'
                        v-model="updateForm.address"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12"></el-col>
          <el-col :span="12">
            <el-form-item>
              <div class='mapContent'>
                <el-button type="success"
                           size="medium"
                           @click="mapClick">地图</el-button>
                <el-row class="map-container"
                        v-if="dialogVisibleMap">
                  <div id="map"></div>
                </el-row>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 第5行 -->
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="经度"
                          prop="longitude">
              <el-input class="width"
                        placeholder="请输入"
                        style="width:63%"
                        v-model="updateForm.longitude"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="纬度"
                          prop="latitude">
              <el-input class="width"
                        placeholder="请输入"
                        style="width:63%"
                        v-model="updateForm.latitude"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 第7行 -->
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="联系人"
                          prop="contact">
              <el-input class="width"
                        placeholder="请输入"
                        style="width:63%"
                        v-model="updateForm.contact"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人电话"
                          prop="contactPhoneNumber">
              <el-input class="width"
                        placeholder="请输入"
                        style="width:63%"
                        v-model="updateForm.contactPhoneNumber"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-row class="dt-button-box">
            <el-button type="success"
                       size="medium"
                       @click="update('updateForm')">确 定</el-button>
            <el-button class="Fbutton"
                       type="info"
                       @click="updateVisible = false">取 消</el-button>
          </el-row>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 导入 -->
    <el-dialog title="导入数据"
               :visible.sync="importDialog"
               :close-on-click-modal="false"
               append-to-body>
      <el-upload ref="upload"
                 :auto-upload="false"
                 :file-list="fileList"
                 :http-request="myUpload"
                 :limit="1"
                 :on-change="addFile"
                 :on-exceed="handleExceed"
                 :show-file-list="true"
                 accept=".xls, .xlsx"
                 action=""
                 style="text-align: center;">
        <el-row style="display:flex">
          <el-button size="small"
                     style="margin-left: 15px"
                     type="primary">
            <a :href="templateDl"
               class="download"
               download=""
               style="color: #ffffff;text-decoration:none">模板下载</a>
          </el-button>
          <el-button size="small"
                     type="primary">
            选择文件
          </el-button>
        </el-row>
        <div slot="tip"
             class="el-upload__tip"
             style="font-size:10px;color:#ff0000;margin-top:30px;">
          请先下载模板！
        </div>
      </el-upload>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     class="popBtn"
                     @click="confimImportBatch">导 入</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <!-- 余位修改 -->
    <el-dialog title="余位修改"
               append-to-body
               :visible.sync="updateYuweiVisible">
      <el-form :model="updateYuweiForm"
               :rules="rules"
               ref="updateYuweiForm"
               size="small"
               label-width="110px">
        <el-row style="color:white;margin:0 0 20px 55px;">
          当前余位:{{ currentEmptySpace }}
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="余位数"
                          prop="emptySpace">
              <el-input v-model="updateYuweiForm.emptySpace"
                        style="width:63%"
                        class="width input"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-row class="dt-button-box">
            <el-button type="success"
                       size="medium"
                       @click="updateYuwei('updateYuweiForm')">确 定</el-button>
            <el-button class="Fbutton"
                       type="info"
                       @click="updateYuweiVisible = false">取 消</el-button>
          </el-row>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'
import { mapState } from 'vuex'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { dtTable },
  data () {
    //这里存放数据
    return {
      isDisabled:true,
      tableFun: { 'selection-change': this.handleChange },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 15,
      },
      tableColumnList: [
        {
          prop: 'parkName',
          label: '停车场名称',
        },
        {
          prop: 'companyName',
          label: '归属企业名称',
        },
        {
          prop: 'address',
          label: '地址',
        },
        {
          prop: 'parkSpaceNum',
          label: '规划总车位数',
        },
        {
          prop: 'billingRuleDefName',
          label: '计费规则',
        },
        {
          prop: 'contact',
          label: '联系人',
        },
        {
          prop: 'contactPhoneNumber',
          label: '联系方式',
        },
      ],
      tableList: {
        list: [],
      },
      pageNum: 1,
      pageSize: 15, // 每页的数据条数
      searchForm: {}, // 查询条件
      dialogVisibleMap: false,//地图容器显示

      operateList: [
        { name: '运营停车场', isEnabled: "1" },
        { name: '非运营停车场', isEnabled: "0" },
      ],// 运营非运营停车场类型 1运营 0非运营

      parkNameList: [],// 停车场
      areaRoadList: [],//行政区域
      regionCodeList: [],//行政区域-路段级联
      roadList: [],//所属路段
      parkTypeList: [],// 停车场类型列表
      enterpriseList: [],// 归属企业例表
      billingRulesList: [],// 计费规则列表
      delList: [], // 删除列表

      dialogVisible: false, //是否显示弹出层
      updateVisible: false,//修改弹窗
      instreForm: {},//新增form
      updateForm: {},//修改form

      updateYuweiVisible: false,
      updateYuweiForm: {},// 修改余位
      currentEmptySpace: '暂无',

      rules: {
        parkName: [{ required: true, message: '请输入停车路段名', trigger: 'blur' }],
        parkId: [{ required: true, message: '请输入停车路段编号', trigger: 'blur' }],
        companyId: [{ required: true, message: '请选择所属企业', trigger: 'change' }],
        parkSpaceNum: [{ required: true, message: '请输入规划总车位数' }],
        billingRuleDefId: [{ required: true, message: '请选择计费规则', trigger: 'change' }],
        address: [{ required: true, message: '请输入地址', trigger: 'blur' }],
        longitude: [{ required: true, message: '请输入经度', trigger: 'blur' }],
        latitude: [{ required: true, message: '请输入纬度', trigger: 'blur' }],
        contact: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        contactPhoneNumber: [{ required: true, validator: this.publicFun.verifyTel, trigger: 'blur' }],
        areaCode: [{ required: true, message: '请选择所属区域', trigger: 'change' }],
        roadCode: [{ required: true, message: '请选择所属路段', trigger: 'change' }],
        emptySpace: [{ required: true, message: '请输入余位数', trigger: 'blur' }],
      },

      importDialog: false, // 导入弹窗
      fileList: [],
      templateDl: "",
    }
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      theme: (state) => state.app.theme,
    })
  },
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
     //菜单按钮权限
     this.buttonList = localStorage.getItem('buttonListByUser')
    this.buttonShow();
    this.templateDl = this.$downloadBaseUrl + "ParkController/downloadTemplate";
    this.queryParkList()
    this.queryCompany()
    this.queryBillingRuleList()
    this.getRegion()
    this.queryTableList()
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
     //菜单按钮展示权限
     buttonShow () {
      const buttonMenuName = JSON.parse(this.buttonList)
      const menuNames = [];
      for (let i = 0; i < Object.keys(buttonMenuName).length; i++) {
        const menuName = buttonMenuName[i].menuName;
        menuNames.push(menuName);
        if (menuNames[i] === '新增') {
          this.insert_user = true
        }
        else if (menuNames[i] === '修改') {
          this.click_update = true
        }
        else if (menuNames[i] === '删除') {
          this.click_delete = true
        }
        else if (menuNames[i] === '余位修改') {
          this.update_yuwei = true
        }
        else if (menuNames[i] === '是否运营') {
          this.isDisabled = false
        }
        else if (menuNames[i] === '导入') {
          this.import_dialog = true
        }
      }
    },
    // 是否运营
    changeSwitch (row) {
      if (row.isEnabled === '1') {
        let info = {
          parkId: row.parkId,
          isEnabled: "1"
        }
        this.$yardManagementApi.changeParkStatus(info).then(response => {
          if (response.resultCode === '2000') {
            this.$message({ message: '设置运营停车场成功', type: 'success' });
            this.queryTableList()
          }
        })
      }
      else if (row.isEnabled === '0') {
        let info = {
          parkId: row.parkId,
          isEnabled: "0"
        }
        this.$yardManagementApi.changeParkStatus(info).then(response => {
          if (response.resultCode === '2000') {
            this.$message({ message: '设置非运营停车场成功', type: 'success' });
            this.queryTableList()
          }
        })
      }
    },
    // 点击修改余位
    handleClickUpdateYuwei (row) {
      let info = {
        parkId: row.parkId,
      }
      this.$yardManagementApi.getEmptySpace(info).then(res => {
        if (!res.resultEntity) {
          this.currentEmptySpace = '暂无'
        } else {
          this.currentEmptySpace = res.resultEntity
        }
        if (this.updateYuweiForm.emptySpace) {
          this.updateYuweiForm.emptySpace = ""
        }
        this.updateYuweiForm = {}
        this.updateYuweiForm = row
        this.updateYuweiVisible = true
      })
    },
    // 余位修改确认
    updateYuwei (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let info = {
            parkId: this.updateYuweiForm.parkId,
            emptySpace: this.updateYuweiForm.emptySpace
          }
          this.$yardManagementApi.updateEmptySpace(info).then(() => {
            this.$message({ message: '修改成功', type: 'success' });
            this.queryTableList()
            this.updateYuweiVisible = false
          })
        } else {
          return false;
        }
      });
    },
    // 上传文件
    addFile (file) {
      if (!(file.name.endsWith("xls") || file.name.endsWith("xlsx"))) {
        this.fileList = [];
        this.$message.warning(`文件格式有误,请选择正确的Excel文件`);
      }
    },
    // 限制文件
    handleExceed () {
      this.$message.warning(`对不起,一次仅限上传一个文件！`);
    },
    // 上传文件  
    myUpload (content) {
      let _self = this;
      var FileController = "";
      FileController = this.$downloadBaseUrl + "ParkController/uploadOut";
      var form = new FormData();
      form.append("file", content.file);
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = onloadFun;
      xhr.open("POST", FileController, true);
      xhr.send(form);
      function onloadFun () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          var resText = JSON.parse(xhr.responseText);
          if (resText.resultCode === "2000") {
            _self.fileList = [];
            _self.$message({ message: "导入成功", type: "success" });
            _self.importDialog = false;
            _self.queryTableList();
          } else {
            _self.$message.error({ message: "对不起！文件上传失败", type: "error" });
          }
        }
      }
    },
    // 确认导入按钮
    confimImportBatch () {
      this.$refs.upload.submit();
      this.importDialog = false;
    },
    // 获取停车场列表
    queryTableList () {
      this.searchForm["pageNum"] = this.pageNum
      this.searchForm["pageSize"] = this.pageSize
      this.searchForm["parkTypeCode"] = "2"
      this.$yardManagementApi.ParkController(this.searchForm).then(res => {
        this.tableList.list = res.resultEntity.list
        this.paginationConfig.total = res.resultEntity.total
      })
    },
    //分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    // 查询按钮
    handleClickSearch () {
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.queryTableList()
    },
    // 重置按钮
    resetForm () {
      this.searchForm = {}
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.queryTableList()
    },
    //新增
    new () {
      this.instreForm.parkSpaceNum = Number(this.instreForm.parkSpaceNum)
      let info = { ...this.instreForm, parkTypeCode: '2' }
      this.$yardManagementApi.newParkController(info).then(() => {
        this.$message({ message: '新增成功', type: 'success' });
        this.queryTableList()
        this.dialogVisible = false
      }).catch((error) => {
        this.$message({ message: error.resultMsg });
      })
    },
    //修改
    update (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.updateForm.parkSpaceNum = Number(this.updateForm.parkSpaceNum)
          let info = this.updateForm
          this.$yardManagementApi.updateParkController(info).then(() => {
            this.$message({ message: '修改成功', type: 'success' });
            this.queryTableList()
            this.updateVisible = false
          })
        } else {
          return false;
        }
      });
    },
    //新增
    handleNew () {
      this.dialogTitle = '新增停车场'
      this.dialogVisible = true
      this.dialogType = 0
    },
    //修改
    handleOrderUpdate (index, row) {
      this.updateForm = this.$deepClone(row)
      this.updateForm.areaRoadList = [this.updateForm.areaCode, Number(this.updateForm.roadCode)]
      this.handleChangeAreaCode(row.areaCode)
      this.updateVisible = true
    },
    //提交form表单
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.new()
        } else {
          return false;
        }
      });
    },
    // 表格选择
    handleChange (val) {
      let parkIdList = []
      for (let index in val) {
        let id = val[index].parkId
        parkIdList.push({ parkId: id })
      }
      this.delList = parkIdList
    },
    //批量删除
    deleteList () {
      if (this.delList.length == 0) {
        this.$message({
          message: '没有选择停车场',
          type: 'warning'
        });
      } else {
        this.$confirm('确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.$yardManagementApi.deleteParkControllerBatch(this.delList).then(() => {
            for (let index in this.delList) {
              this.$systemRoleManagement.delRoleAuthority([this.delList[index]]).then(() => { })
            }
            this.$message({ message: '删除成功', type: 'success' });
            this.queryTableList()
          })
        })
      }
    },
    //删除
    handleOrderDelete (index, row) {
      let list = []
      let id = row.parkId
      list.push({ parkId: id })
      this.delList = list
      this.deleteList()
    },
    //批量删除
    handleDel () {
      this.deleteList()
    },
    // 弹出框地图
    mapClick () {
      this.dialogVisibleMap = !this.dialogVisibleMap
      setTimeout(() => {
        this.initMap()
      }, 0);
    },
    //初始化地图
    initMap () {
      let themeType = {
        dark: 'amap://styles/a1ef87b8517f7bb18e73e47a65ac69c9',
        light: 'amap://styles/37bc333a00b6dc86dbd9309cae53802f'
      }
      this.map = new window.AMap.Map('map', {
        mapStyle: themeType[this.theme],
        resizeEnable: true,
        zoom: 17, // 缩放级别
        zooms: [3, 20], // 缩放级别
        center: [106.562865, 29.600376], // 中心点坐标
        viewMode: '3D',//使用3D视图
        pitch: 60,
      })
      //地图点击事件
      let that = this
      this.map.on("click", showInfoClick);
      function showInfoClick (e) {
        let longitude = e.lnglat.getLng(); //获取经度
        let latitude = e.lnglat.getLat(); //获取纬度
        let form = that.$deepClone(that.instreForm)
        form.longitude = longitude
        form.latitude = latitude
        that.instreForm = form
        let form1 = that.$deepClone(that.updateForm)
        form1.longitude = longitude
        form1.latitude = latitude
        that.updateForm = form1
      }
    },
    // 获取停车场名称
    queryParkList () {
      let info = {
        columnName: ['park_id', 'park_name'], //有且只有两个 id放前面名称放后面
        tableName: 'tb_park',
        "whereStr": [
          {
            "colName": "park_type_code",
            "value": '2'
          }
        ]
      }
      this.$queryDict.queryDictWithAuth(info).then((response) => {
        this.parkNameList = response.resultEntity
      })
    },
    // 获取归属企业
    queryCompany () {
      let info = {
        columnName: ['company_id', 'company_name'],
        tableName: 'tb_company',
      }
      this.$queryDict.queryDict(info).then(res => {
        this.enterpriseList = res.resultEntity
      })
    },
    //获取计费规则
    queryBillingRuleList () {
      let info = {
        columnName: ['billing_rule_def_id', 'billing_rule_def_name'],
        tableName: 'tb_billing_rule_def',
        whereStr: [
          {
            colName: 'delete_flag',
            value: '1',
          },
        ],
      }
      this.$queryDict.queryDict(info).then(res => {
        this.billingRulesList = res.resultEntity
      })
    },
    // 获取行政区
    getRegion () {
      let info = {
        "columnName": ["region_code", "region_name"],
        "tableName": "tb_region"
      }
      this.$queryDict.queryDict(info).then(res => {
        this.regionCodeList = res.resultEntity
      })
    },
    // 行政区域发生改变
    handleChangeAreaCode (val) {
      let info = {
        columnName: ['road_code', 'road_name'],
        tableName: 'tb_road',
        whereStr: [
          {
            colName: 'region_code',
            value: val,
          },
        ],
      }
      this.$queryDict.queryDict(info).then((response) => {
        this.roadList = response.resultEntity
      })
    },
    // 获取路段
    popClose () {
      this.instreForm = {}
      this.$refs.instreForm.resetFields();
    },
    handleCheck (val) {
      this.instreForm.areaCode = val[0]
      this.instreForm.roadCode = val[1]
    },
    updateHandleCheck (val) {
      this.updateForm.areaCode = val[0]
      this.updateForm.roadCode = val[1]
    },
  },
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
$topSelectHeight: 185px;
.mainbody {
  height: 100%;
  // 顶部查询条件
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
}

.tableRow {
  width: 100%;
  @include background('blockBg');
  margin-top: 16px;
  padding-left: 20.2px;
  padding-right: 20.2px;
  padding-top: 5px;
  .tableTitle {
    height: 32px;
    padding-top: 15px;
    .topButton {
      display: flex;
      padding-left: 91x;
      float: right;
    }
  }
  .tableContent {
    margin-top: 25px;
  }
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
.mapContent {
  position: relative;
  .map-container {
    width: 450px;
    height: 610px;
    position: absolute;
    margin-top: -85%;
    right: -268%;
    #map {
      width: 100%;
      height: 100%;
      z-index: 9999;
    }
  }
}
</style>
